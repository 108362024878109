<template>
  <div v-lazyload class="image__wrapper" style="height:100%; width:100%" >
    <img
      class="image__item"
      :data-url="src"
      alt="image"
      :style="cover?'object-fit: cover;height:100%;width:100%':'object-fit: contain;height:100%;width:100%'"
    >
  </div>
</template>

<script>
export default {
    name: "ImageItem",
  props: {
    src: {
      type: String,
      required: true
    },
    cover: {
      type: Boolean,
      required: false
    }
  }

}
</script>

<style>



</style>